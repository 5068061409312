import { useState, useMemo, useEffect, FC, useRef, forwardRef, useImperativeHandle, memo } from 'react'
import 'xgplayer/dist/index.min.css'
import { randomGenKey } from '@/hooks/encrypt'
import { useAppStore } from '@/stores/app'
import Player, { Events, I18N } from 'xgplayer'
import JP from 'xgplayer/es/lang/jp'

type IProps = {
  ref?: any
  videoKey?: string // "video-key"
  width?: string
  height?: string
  control: boolean
  loop?: boolean
  autoplay?: boolean
  autoplayMuted?: boolean
  poster?: string
  src: string
  // --- 待处理
  fitVideoSize?: any
  // fitVideoSize: {
  //   type: String,
  //   default: "auto",
  //   validator: (value: string) => {
  //     // fixed 保持容器宽/高，不做适配
  //     // fixWidth 保持容器宽度，适配高度
  //     // fixHeight 保持容器高度，适配宽度
  //     // auto 根据视频溢出宽/高，适配容器宽/高
  //     return ["auto", "fixed", "fixWidth", "fixHeight"].includes(value)
  //   }
  // }
  key?: any
  className?: string
  style?: any
}

const AppVideo: FC<IProps> = forwardRef((props, ref) => {
  const videoRef = useRef(null)
  const { language } = useAppStore()
  // I18N.use(JP)   // --- 待处理
  const random1000 = 'video-key' + randomGenKey(4)
  const componentKey = useMemo(() => random1000, [])
  // const emits = defineEmits(["play", "loadstart", "onceplay"])  // 待处理
  const player = useRef<any>(null)
  const [canPlay, setCanPlay] = useState(false)

  // 待处理
  useEffect(() => {
    // player.current = new Player({
    //   id: componentKey,
    //   url: props.src,
    //   lang: language === 'ja-JP' ? 'jp' : language === 'zh-CN' ? 'zh' : 'en',
    //   width: props.width,
    //   height: props.height,
    //   poster: props.poster,
    //   controls: props.control,
    //   fitVideoSize: props.fitVideoSize,
    //   loop: props.loop,
    //   autoplay: props.autoplay,
    //   autoplayMuted: props.autoplay ? true : props.autoplayMuted
    // })
    // player.current.on('play', () => {
    //   console.log('--- 待处理')
    //   // emits("play")
    // })
    // player.current.on('loadstart', () => {
    //   // emits("loadstart")
    //   setCanPlay(true)
    // })
    // player.current.once('play', () => {
    //   // emits("onceplay")
    // })
  }, [])

  const playVideo = () => {
    if (canPlay) {
      player.current.play()
    } else {
      setTimeout(() => {
        playVideo()
      }, 100)
    }
  }

  const pauseVideo = () => {
    console.log('视频暂停')
    player.current.pause()
  }

  const replayVideo = () => {
    player.current.replay()
  }

  // 待观察
  useImperativeHandle(ref, () => ({
    player: () => {
      videoRef.current.player()
    },
    videoKey: () => {
      return props.videoKey
    },
    playVideo: playVideo,
    pauseVideo: pauseVideo,
    replayVideo: replayVideo
  }))
  // defineExpose({ player, videoKey: props.videoKey, playVideo, pauseVideo, replayVideo })

  return (
    <>
      <div ref={videoRef}>
        <div id="componentKey"></div>
      </div>
    </>
  )
})

export default memo(AppVideo)
