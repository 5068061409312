import { useState, useEffect, useMemo, memo } from 'react'
import { useSetState } from 'ahooks'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { usePathname } from 'next/navigation'
import { jumpURL } from '@/lib/jumpURL'
import {
  getJptPoolsInfo as JPTPools,
  getJptAwardHistory as JPTHistory,
  getJptRankInfo as JPTRank
} from '@/api/home'
import { px2Scalepx } from '@/hooks/useRem'
import styles from './_common_css/jackPot.module.scss'
import { CSSTransition } from 'react-transition-group'
// 待处理
// import { Vue3Marquee } from "vue3-marquee"
// import "vue3-marquee/dist/style.css"
import Marquee from 'react-fast-marquee'
// import AnimationNum from "@/components/base/AnimationNum.vue"
import { numThFilter } from '@/lib/filter'
import AppVideo from '@/components/common/appVideo'
import dayjs from 'dayjs'
import CryptoJS from 'crypto-js'
import { Modal, Tabs } from 'antd'
import { useShallow } from 'zustand/react/shallow'

const JackPot = () => {
  const { t } = useTranslation()
  const { isBigScreen, language } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      language: state.language
    }))
  )
  const pathName = usePathname()
  const localStorageKey = 'jackpot-isShowMore'
  const defaultIsShowMore = localStorage.getItem(localStorageKey) === 'true' || false
  // const activeTab = useState('1')
  const [jackPotData, setJackPotData] = useSetState<any>({ url: '' })
  const [isShowMore, setIsShowMore] = useState(false)
  const [isShowDialog, setIsShowDialog] = useState(false)

  const showMore = () => {
    setIsShowMore(!isShowMore)
  }

  useEffect(() => {
    localStorage.setItem(localStorageKey, String(isShowMore)) // 待处理
  }, [isShowMore])

  let timer = null

  useEffect(() => {
    getJptPoolsInfo()
    getJptAwardHistory()
    getJptRankInfo()
    timer = setInterval(() => {
      // 待处理  || router.href === "/ja/"
      if (pathName === '/') {
        getJptPoolsInfo()
        getJptAwardHistory()
        getJptRankInfo()
        // console.log('路由待处理')
      }
    }, 60000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const [jpPoorInfo, setJpPoorInfo] = useSetState({
    total: 0,
    totalBonusAmount: 0,
    data: []
  })

  const [jpPoorHistory, setJpPoorHistory] = useSetState({ hisRecords: [] })
  const [dialogData, setDialogData] = useSetState({ videoUrl: '' })
  const [jpPoorRankInfo, setJpPoorRankInfo] = useState([])
  const jpPoorRankInfoFirst = useMemo(() => {
    return jpPoorRankInfo[0]
  }, [])
  const jpPoorRankInfoSecond = useMemo(() => {
    return jpPoorRankInfo[1]
  }, [])
  const jpPoorRankInfoThird = useMemo(() => {
    return jpPoorRankInfo[2]
  }, [])
  const jpPoorRankInfoOther = useMemo(() => {
    return jpPoorRankInfo.filter((item, index) => {
      return index > 2 && index < 20
    })
  }, [])

  const hisRecords = useMemo(() => {
    const list = jpPoorHistory?.hisRecords || []
    return list.sort((a, b) => {
      // 待处理
      return 1
      // return dayjs(b.jointime) - dayjs(a.jointime)
    })
  }, [])
  const jpPoorHistoryMarquee = useMemo(() => {
    const hisRecords = jpPoorHistory?.hisRecords || []
    const list = hisRecords.map((item) => {
      return {
        ...item,
        msgContent: t('home.jackpot.marqueeText', { 0: item.jointime, 1: numThFilter(item.amount, 2) })
      }
    })

    const listLength5 = list.filter((item, index) => {
      return index < 5
    })

    return listLength5
  }, [])

  // 牌值	梅花		牌值	方块		牌值	黑桃		牌值	红心
  // 1	A		      17	A		    33	A		      49	A
  // 2	2		      18	2		    34	2		      50	2
  // 3	3		      19	3		    35	3		      51	3
  // 4	4		      20	4		    36	4		      52	4
  // 5	5		      21	5		    37	5		      53	5
  // 6	6		      22	6		    38	6		      54	6
  // 7	7		      23	7		    39	7		      55	7
  // 8	8		      24	8		    40	8		      56	8
  // 9	9		      25	9		    41	9		      57	9
  // 10	10		    26	10		  42	10		    58	10
  // 11	J		      27	J		    43	J		      59	J
  // 12	Q		      28	Q		    44	Q		      60	Q
  // 13	K		      29	K		    45	K		      61	K

  const getCardImg = (item, index, type) => {
    if (!item.cardlist) {
      return
    }
    const cardList = item.cardlist.split(';')
    let pList = cardList[0].split(' ') //闲家
    let bList = cardList[1].split(' ') //庄家

    let num = 1
    if (type == 'p') {
      // 没有第三张牌 返回null
      if (!pList[index]) {
        return null
      }
      num = pList[index]
    } else {
      // 没有第三张牌 返回null
      if (!bList[index]) {
        return null
      }
      num = bList[index]
    }
    const cardMap = {
      1: '/static/images/theme-red/poker/clubs_A.png',
      2: '/static/images/theme-red/poker/clubs_02.png',
      3: '/static/images/theme-red/poker/clubs_03.png',
      4: '/static/images/theme-red/poker/clubs_04.png',
      5: '/static/images/theme-red/poker/clubs_05.png',
      6: '/static/images/theme-red/poker/clubs_06.png',
      7: '/static/images/theme-red/poker/clubs_07.png',
      8: '/static/images/theme-red/poker/clubs_08.png',
      9: '/static/images/theme-red/poker/clubs_09.png',
      10: '/static/images/theme-red/poker/clubs_10.png',
      11: '/static/images/theme-red/poker/clubs_j.png',
      12: '/static/images/theme-red/poker/clubs_q.png',
      13: '/static/images/theme-red/poker/clubs_k.png',

      17: '/static/images/theme-red/poker/diamonds_A.png',
      18: '/static/images/theme-red/poker/diamonds_02.png',
      19: '/static/images/theme-red/poker/diamonds_03.png',
      20: '/static/images/theme-red/poker/diamonds_04.png',
      21: '/static/images/theme-red/poker/diamonds_05.png',
      22: '/static/images/theme-red/poker/diamonds_06.png',
      23: '/static/images/theme-red/poker/diamonds_07.png',
      24: '/static/images/theme-red/poker/diamonds_08.png',
      25: '/static/images/theme-red/poker/diamonds_09.png',
      26: '/static/images/theme-red/poker/diamonds_10.png',
      27: '/static/images/theme-red/poker/diamonds_j.png',
      28: '/static/images/theme-red/poker/diamonds_q.png',
      29: '/static/images/theme-red/poker/diamonds_k.png',

      33: '/static/images/theme-red/poker/spades_A.png',
      34: '/static/images/theme-red/poker/spades_02.png',
      35: '/static/images/theme-red/poker/spades_03.png',
      36: '/static/images/theme-red/poker/spades_04.png',
      37: '/static/images/theme-red/poker/spades_05.png',
      38: '/static/images/theme-red/poker/spades_06.png',
      39: '/static/images/theme-red/poker/spades_07.png',
      40: '/static/images/theme-red/poker/spades_08.png',
      41: '/static/images/theme-red/poker/spades_09.png',
      42: '/static/images/theme-red/poker/spades_10.png',
      43: '/static/images/theme-red/poker/spades_j.png',
      44: '/static/images/theme-red/poker/spades_q.png',
      45: '/static/images/theme-red/poker/spades_k.png',

      49: '/static/images/theme-red/poker/hearts_A.png',
      50: '/static/images/theme-red/poker/hearts_02.png',
      51: '/static/images/theme-red/poker/hearts_03.png',
      52: '/static/images/theme-red/poker/hearts_04.png',
      53: '/static/images/theme-red/poker/hearts_05.png',
      54: '/static/images/theme-red/poker/hearts_06.png',
      55: '/static/images/theme-red/poker/hearts_07.png',
      56: '/static/images/theme-red/poker/hearts_08.png',
      57: '/static/images/theme-red/poker/hearts_09.png',
      58: '/static/images/theme-red/poker/hearts_10.png',
      59: '/static/images/theme-red/poker/hearts_j.png',
      60: '/static/images/theme-red/poker/hearts_q.png',
      61: '/static/images/theme-red/poker/hearts_k.png'
    }
    return cardMap[num]
  }

  const getNumImage = (num) => {
    const numMap = {
      0: '/static/images/theme-red/nb/0.png',
      1: '/static/images/theme-red/nb/1.png',
      2: '/static/images/theme-red/nb/2.png',
      3: '/static/images/theme-red/nb/3.png',
      4: '/static/images/theme-red/nb/4.png',
      5: '/static/images/theme-red/nb/5.png',
      6: '/static/images/theme-red/nb/6.png',
      7: '/static/images/theme-red/nb/7.png',
      8: '/static/images/theme-red/nb/8.png',
      9: '/static/images/theme-red/nb/9.png',
      ',': '/static/images/theme-red/nb/poin.png',
      '.': '/static/images/theme-red/nb/point.png',
      $: '/static/images/theme-red/nb/money.png'
    }

    return numMap[num]
  }

  const getJptPoolsInfo = () => {
    let params = {
      providerCurrency: 'USD'
    }
    return JPTPools(params).then((res: any) => {
      if (res?.success) {
        setJpPoorInfo({ data: res.data })
        getTotalAmount()
      }
    })
  }
  //提出 总奖池数据
  function getTotalAmount() {
    let total = 0 // 总奖池数据
    let total1 = 0 // 总盈利数据
    let data = jpPoorInfo.data ?? []
    for (let i = 0; i < data.length; i++) {
      if (data[i].pool == '1' || data[i].pool == '3' || data[i].pool == '7' || data[i].pool == '9') {
        total += Number(data[i].amount)
        total1 += Number(data[i].payout)
      }
    }
    setJpPoorInfo({ total: total })
    setJpPoorInfo({ totalBonusAmount: total1 })
  }

  // const getVideoToken = (data) => {
  //   const chipper_iv = data.iv
  //   const private_key = data.p
  //   const jsonDataObj = {
  //     t: data.t, // 对网站方无用 填1即可
  //     v: data.v, // 视频id
  //     l: data.l, // 请求来源 与网站唯一对应即可。可传外部id
  //     s: dayjs().format("YYYYMMDD"), //时间信息 时间格式到天即可 //"20230501"
  //     sk: data.sk, // 固定值 c519b9fbf3d3699b
  //     sp: data.sp // 必填 1
  //   }
  //   const aesFlvOption = {
  //     iv: CryptoJS.enc.Utf8.parse(chipper_iv),
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7
  //   }
  //   const aes128Cbc = CryptoJS.AES

  //   let key = CryptoJS.enc.Utf8.parse(private_key)
  //   let enstr = aes128Cbc.encrypt(JSON.stringify(jsonDataObj), key, aesFlvOption)
  //   let encrypt = enstr.ciphertext
  //   return encodeURIComponent(CryptoJS.enc.Base64.stringify(encrypt))
  // }

  const getJptAwardHistory = () => {
    let params = {
      providerCurrency: 'USD'
    }
    return JPTHistory(params).then((res) => {
      const list = res.data?.map((item) => {
        return {
          ...item,
          sk: atob(item.sk || ''),
          iv: atob(item.iv || ''),
          p: atob(item.p || '')
        }
      })
      setJpPoorHistory({
        hisRecords: list
      })
    })
  }
  const getJptRankInfo = () => {
    let params = {
      providerCurrency: 'USD',
      orderBy: 1,
      pageSize: 20
    }
    JPTRank(params).then((res) => {
      if (res.data) {
        let arr = res.data?.map((item) => {
          const username = item.username || ''
          return {
            ...item,
            username: username.length > 10 ? username.slice(-10) : username
          }
        })
        arr.length > 20 ? (arr.length = 20) : ''
        setJpPoorRankInfo(arr)
        //rank 取钱20
      }
    })
  }

  const showVideo = (item) => {
    setIsShowDialog(true)
    setDialogData(item)
  }

  return (
    <>
      <div className={cn(styles['jack-pot-red-wrap'], styles[isBigScreen ? 'pc' : 'h5'])}>
        <section
          className={cn('jack-pot-red-new', isBigScreen ? 'pc' : 'h5')}
          onClick={() => jumpURL(jackPotData.url)}
        >
          <div className={'jack-pot-warp'}>
            <strong>{t('home.baccaratJackpot')}</strong>
            <p className={'amount'}>
              <small>$</small>
              <strong>{numThFilter(jpPoorInfo.total || 0)}</strong>
            </p>
          </div>
          <div className={'jack-pot-marquee-wrap'}>
            <div className={'maruee-note-icon'}>
              <img src="/static/images/theme-red/note.svg" />
            </div>
            <div className={'jack-pot-marquee'}>
              {/*  待观察*/}
              {/* <Vue3Marquee :duration="jpPoorHistoryMarquee.length * (isBigScreen ? 20 : 12)">
                  <a
                    v-for="(item, index) in jpPoorHistoryMarquee"
                    :key="index"
                    className={"marquee_list"
                    target="_blank"
                    v-html="item.msgContent"
                  ></a>
                </Vue3Marquee> */}
              {/* 没有持续时间 */}
              {/*  duration="jpPoorHistoryMarquee.length * (isBigScreen ? 20 : 12)" */}
              <Marquee>
                {jpPoorHistoryMarquee.map((item, index) => (
                  <a
                    key={index}
                    className={'marquee_list'}
                    target="_blank"
                    dangerouslySetInnerHTML={{ __html: item.msgContent }}
                  ></a>
                ))}
              </Marquee>
            </div>
            <div onClick={showMore} className={'jack-pot-more'}>
              {t('home.jackpot.moreBtn')}
              <img
                className={cn('arrow', isShowMore && 'active')}
                src="/static/images/theme-red/icon-chevron-right.svg"
              />
            </div>
          </div>
        </section>
        <CSSTransition timeout={300} in={isShowMore} classNames={'fade'}>
          <div>
            {isBigScreen ? (
              <div className={'winning-list'}>
                <div className={'winning-list-rank'}>
                  {jpPoorRankInfoFirst && (
                    <div className={cn('rank-1', 'rank-top')}>
                      <div className={'name'}>{jpPoorRankInfoFirst.username}</div>
                      <div className={'value'}>${numThFilter(jpPoorRankInfoFirst.amount, 2)}</div>
                      <div className={'date'}>{dayjs(jpPoorRankInfoFirst.jointime).format('YYYY.MM.DD')}</div>
                    </div>
                  )}

                  {jpPoorRankInfoSecond && (
                    <div className={cn('rank-2', 'rank-top')}>
                      <div className={'name'}>{jpPoorRankInfoSecond.username}</div>
                      <div className={'value'}>${numThFilter(jpPoorRankInfoSecond.amount, 2)}</div>
                      <div className={'date'}>
                        {dayjs(jpPoorRankInfoSecond.jointime).format('YYYY.MM.DD')}
                      </div>
                    </div>
                  )}

                  {jpPoorRankInfoThird && (
                    <div className={cn('rank-3', 'rank-top')}>
                      <div className={'name'}>{jpPoorRankInfoThird.username}</div>
                      <div className={'value'}>${numThFilter(jpPoorRankInfoThird.amount, 2)}</div>
                      <div className={'date'}>{dayjs(jpPoorRankInfoThird.jointime).format('YYYY.MM.DD')}</div>
                    </div>
                  )}

                  <div className={cn('rank-list', 'scrollbar')}>
                    {jpPoorRankInfoOther.map((item, index) => (
                      <div key={index} className={'rank-list-item'}>
                        <div className={'index'}>{index + 4}</div>
                        <div className={'date'}>{dayjs(item.jointime).format('YYYY.MM.DD')}</div>
                        <div className={'name'}>{item.username}</div>
                        <div className={'value'}>$ {numThFilter(item.amount, 2)}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={'winning-table'}>
                  <div className={'winning-record'}>
                    <div className={'record-name'}>{t('home.jackpot.record')}</div>
                    <div className={'record-total'}>
                      <div className={'record-total-label'}>{t('home.jackpot.totalAmount')}</div>
                      <div className={'record-total-value'}>
                        {/* 待处理 */}
                        {/* <AnimationNum num="jpPoorInfo.totalBonusAmount">
                        <template v-slot="slotProps">
                          <span>${ numThFilter(slotProps.value, 2) }</span>
                        </template>
                      </AnimationNum> */}
                      </div>
                    </div>
                  </div>
                  <div className={cn('record-list', language)}>
                    <div className={cn('record-list-header', 'record-list-row')}>
                      <div className={'record-list-item'}>{t('home.jackpot.time')}</div>
                      <div className={'record-list-item'}>{t('home.jackpot.round')}</div>
                      <div className={cn('record-list-item', 'card-type')}>{t('home.jackpot.cardType')}</div>
                      <div className={cn('record-list-item', 'player')}>{t('home.jackpot.player')}</div>
                      <div className={'record-list-item'}>{t('home.jackpot.amount')}</div>
                      <div className={cn('record-list-item', 'more')}>{t('home.jackpot.more')}</div>
                    </div>
                    <div className={cn('record-list-table-content', 'scrollbar')}>
                      {hisRecords.map((item, index) => (
                        <div key={index} className={'record-list-row'}>
                          <div className={'record-list-item'}>
                            {dayjs(item.awardDate).format('YYYY.MM.DD')}
                          </div>
                          <div className={'record-list-item'}>{item.gmcode}</div>
                          <div className={cn('record-list-item', 'card-type')}>
                            <div className={'card-type-player'}>{t('home.jackpot.player1')}</div>
                            <div className={'card-detail'}>
                              <img src={getCardImg(item, 0, 'p')} alt="" />
                              <img src={getCardImg(item, 1, 'p')} alt="" />
                              <img src={getCardImg(item, 2, 'p')} alt="" />
                            </div>
                            <div className={'card-type-player'}>{t('home.jackpot.banker1')}</div>
                            <div className={'card-detail'}>
                              <img src={getCardImg(item, 0, 'b')} alt="" />
                              <img src={getCardImg(item, 1, 'b')} alt="" />
                              <img src={getCardImg(item, 2, 'b')} alt="" />
                            </div>
                          </div>
                          <div className={cn('record-list-item', 'player')}>{item.player}</div>
                          <div className={'record-list-item'}>${numThFilter(item.amount, 2)}</div>
                          <div onClick={() => showVideo(item)} className={cn('record-list-item', 'more')}>
                            <img src="/static/images/theme-red/arrows_icon.png" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={'winning-list-h5'}>
                <Tabs
                  // activeKey={String(activeTab)}
                  defaultActiveKey="1"
                  centered
                  items={[
                    {
                      key: '1',
                      label: t('home.jackpot.rankListTitle'),
                      children: (
                        <>
                          <div className={'total-amount'}>
                            <div className={'total-amount-label'}>{t('home.jackpot.totalAmount')}</div>
                            <div className={'total-amount-value'}>
                              ${numThFilter(jpPoorInfo.totalBonusAmount, 2)}
                            </div>
                          </div>
                          <div className={'record-list'}>
                            {hisRecords.map((item, index) => (
                              <div key={index} className={'record-list-row'}>
                                <div className={cn('record-list-item', 'time')}>
                                  {dayjs(item.jointime).format('YYYY.MM.DD')}
                                </div>
                                <div className={cn('record-list-item', 'player')}>{item.player}</div>
                                <div className={cn('record-list-item', 'amounts')}>
                                  {numThFilter(item.amount, 2)}
                                  <img
                                    className={'currency'}
                                    src={`/static/images/header/wallets/currencies/USD.png`}
                                  />
                                </div>
                                <div
                                  onClick={() => showVideo(item)}
                                  className={cn('record-list-item', 'more')}
                                >
                                  <img src="/static/images/theme-red/arrows_icon.png" />
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )
                    },
                    {
                      key: '2',
                      label: t('home.jackpot.record'),
                      forceRender: true,
                      children: (
                        <>
                          <div className={'total-amount'}>
                            <div className={'total-amount-label'}>{t('home.jackpot.totalAmount')}</div>
                            <div className={'total-amount-value'}>
                              ${numThFilter(jpPoorInfo.totalBonusAmount, 2)}
                            </div>
                          </div>
                          <div className={cn('record-list', 'list-2')}>
                            {jpPoorRankInfo.map((item, index) => (
                              <div key={index} className={'record-list-row'}>
                                <div className={cn('record-list-item', 'index')}>
                                  <span>{index + 1}</span>
                                </div>
                                <div className={cn('record-list-item', 'amounts')}>
                                  {numThFilter(item.amount, 2)}
                                  <img
                                    className={'currency'}
                                    src={'/static/images/header/wallets/currencies/USD.png'}
                                  />
                                </div>
                                <div className={cn('record-list-item', 'time')}>
                                  {dayjs(item.jointime).format('YYYY.MM.DD')}
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )
                    }
                  ]}
                ></Tabs>
              </div>
            )}

            <Modal
              open={isShowDialog}
              footer="null"
              maskClosable={false}
              closable={false}
              centered
              className={cn('jackpot-dialog', isBigScreen ? 'pc' : 'h5')}
            >
              <div className={'jackpot-dialog-content'}>
                <div className={'jackpot-title'}>{t('home.jackpot.dialogTitle')}</div>
                <div onClick={() => setIsShowDialog(false)} className={'jackpot-dialog-close'}></div>
                <AppVideo
                  width={isBigScreen ? px2Scalepx(480) + 'px' : px2Scalepx(336) + 'px'}
                  height={isBigScreen ? px2Scalepx(270) + 'px' : px2Scalepx(186) + 'px'}
                  control
                  key={dialogData.videoUrl}
                  src={dialogData.videoUrl}
                ></AppVideo>
              </div>
            </Modal>
          </div>
        </CSSTransition>
      </div>
    </>
  )
}

export default memo(JackPot)
